<template>
  <v-container fluid>
    <CRUD id-method="params" :fields="fields" :table="table" :api="api">
      <template v-slot:[`form.input.AgentsAssigned`]="{}">
        <CRUD
          :alwaysPostAll="true"
          ref="paTable"
          @clicked:create="openForm"
          @form:load="initForm"
          :key="payAssignmentData.table.key"
          form-size="500"
          :fields="payAssignmentData.fields"
          :table="payAssignmentData.table"
          :api="{
            url: '/ccms/table/',
            params: {
              tableName: 'payAssignment',
              projectId: $route.params.id
            }
          }"
        >
        </CRUD>
      </template>
    </CRUD>
    <PayAssignmentForm ref="paForm" @saved="onPAFormSaved" />
  </v-container>
</template>

<script>
import CRUD from "@/components/util/CRUD/CRUD";
import PayAssignmentForm from "@/components/ccms/PayAssignmentForm";

export default {
  components: {
    CRUD,
    PayAssignmentForm
  },
  data() {
    return {
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.project"
        }
      },
      table: {
        text: "Agent Assignment & Pay",
        key: "ProjectID",
        default: {
          itemsPerPage: 20,
          sortBy: ["UpdateDate"],
          sortDesc: [true]
        },
        components: {
          filter: true,
          delete: false,
          add: false,
          edit: false
        }
      },
      fields: [
        {
          text: "Project Name",
          name: "ProjectNM",
          searchable: true,
          input: {
            cols: 12
          }
        },
        {
          text: "Sales Manager",
          name: "BDMID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 1 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "PM",
          name: "PMID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.staffmst",
            key: "StaffID",
            display: "StaffNM",
            where: `StaffTypeID = 4 AND AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Start Date",
          name: "PStartDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "End Date",
          name: "PEndDate",
          searchable: true,
          input: {
            type: "D"
          }
        },
        {
          text: "Client",
          name: "ClientID",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.clientmst",
            key: "ClientID",
            display: "ClientNM",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Status",
          name: "Status",
          searchable: true,
          input: {
            type: "L"
          },
          foreign: {
            table: "cnetd.projectstatus",
            key: "Status_Value",
            display: "Status_Text"
          }
        },
        {
          text: "Action",
          actions: [
            {
              text: "Assign Agents",
              icon: "mdi-account-multiple-plus",
              method: "edit"
            }
          ]
        },
        {
          text: "Agents assigned",
          name: "AgentsAssigned",
          listable: false,
          input: {
            cols: "12"
          }
        }
      ],
      payAssignmentData: {
        table: {
          text: "Agents assigned",
          key: "id",
          default: {
            itemsPerPage: 50,
            sortBy: ["TName", "StartDate", "BaseorIn", "Type"],
            sortDesc: [false, true, false, false]
          },
          components: {
            filter: false,
            add: false
          },
          customActions: [{ name: "create", text: "Add New" }]
        },
        fields: [
          {
            text: "Name",
            name: "TName",
            input: {
              cols: 12,
              if: { update: "disabled" },
              validation: ["mandatory"]
            }
          },
          {
            text: "Hourly Rate",
            name: "HrRate",
            listable: false,
            input: {
              cols: 3,
              if: { update: "disabled" },
              validation: ["mandatory"]
            }
          },
          {
            text: "Start Date",
            name: "StartDate",
            input: {
              type: "D",
              validation: ["mandatory"]
            }
          },
          {
            text: "End Date",
            name: "EndDate",
            input: {
              type: "D",
              validation: ["mandatory"]
            }
          },
          {
            text: "Type",
            name: "BaseorIn",
            editable: false
          },
          {
            text: "Pay Detail",
            name: "Type",
            input: {
              cols: "12"
            }
          },
          {
            text: "Base Rate ($)",
            name: "Rate",
            input: {}
          },
          {
            text: "Pay Type",
            name: "PayType",
            listable: false,
            input: {
              cols: "3",
              type: "L",
              validation: ["mandatory"],
              lov: [
                { text: "$", value: "C" },
                { text: "%", value: "R" }
              ]
            }
          },
          {
            text: "Amount",
            name: "PayValue",
            input: {
              cols: "9",
              validation: ["mandatory"]
            }
          }
        ]
      }
    };
  },
  methods: {
    onPAFormSaved() {
      this.$refs.paTable.loadTable();
    },
    openForm() {
      this.$refs.paForm.open(this.$route.params.id);
    },
    initForm(e) {
      this.payAssignmentData.fields.find((e) => e.name == "Type").input.disabled = false;
      this.payAssignmentData.fields.find((e) => e.name == "HrRate").input.hidden = true;
      this.payAssignmentData.fields.find((e) => e.name == "Rate").input.hidden = true;
      this.payAssignmentData.fields.find((e) => e.name == "Type").input.hidden = true;
      this.payAssignmentData.fields.find((e) => e.name == "PayType").input.hidden = true;
      this.payAssignmentData.fields.find((e) => e.name == "PayValue").input.hidden = true;
      this.payAssignmentData.fields.find((e) => e.name == "TName").input.cols = "12";

      if (e.ID_TYPE == "B") {
        this.payAssignmentData.fields.find((e) => e.name == "TName").input.cols = "9";
        this.payAssignmentData.fields.find((e) => e.name == "HrRate").input.hidden = false;
        this.payAssignmentData.fields.find((e) => e.name == "Rate").input.hidden = false;
      } else if (e.ID_TYPE == "BI") {
        this.payAssignmentData.fields.find((e) => e.name == "Type").input.disabled = true;
        this.payAssignmentData.fields.find((e) => e.name == "Type").input.hidden = false;
        this.payAssignmentData.fields.find((e) => e.name == "PayType").input.hidden = false;
        this.payAssignmentData.fields.find((e) => e.name == "PayValue").input.hidden = false;
      } else if (e.ID_TYPE == "I") {
        this.payAssignmentData.fields.find((e) => e.name == "Type").input.hidden = false;
        this.payAssignmentData.fields.find((e) => e.name == "PayType").input.hidden = false;
        this.payAssignmentData.fields.find((e) => e.name == "PayValue").input.hidden = false;
      }
    }
  }
};
</script>
