var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRUDForm',{ref:"crudForm",attrs:{"id":"+","fields":_vm.fields,"formSize":900,"headerText":"Agent's Pay","loading":_vm.loading,"formActive":_vm.formActive},on:{"save":_vm.onFormSave,"close":_vm.onFormClose},scopedSlots:_vm._u([{key:"form.input.Agents",fn:function(ref){return [_c('v-autocomplete',{attrs:{"items":_vm.agents,"outlined":"","chips":"","color":"blue-grey lighten-2","label":"Agents*","item-text":"name","item-value":"id","multiple":"","rules":[function (value) { return !Array.isArray(value) || value.length > 0 || 'Required'; }]},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.removeAgent(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.rate)}})],1)]}}],null,true),model:{value:(_vm.selectedAgents),callback:function ($$v) {_vm.selectedAgents=$$v},expression:"selectedAgents"}})]}},{key:"form.input.divider",fn:function(ref){return [[_c('v-divider')]]}},{key:"form.input.PayType",fn:function(ref){return [_c('v-card',[_c('v-toolbar',{attrs:{"short":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Pay Type")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{attrs:{"item-key":"id","hide-default-footer":"","show-select":"","headers":_vm.payTypeTable.headers,"items":_vm.payTypeTable.items},scopedSlots:_vm._u([{key:"item.amt",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":""},model:{value:(item.amt),callback:function ($$v) {_vm.$set(item, "amt", $$v)},expression:"item.amt"}})]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [(item.type == 'Incentive')?_c('v-text-field',{attrs:{"label":""},model:{value:(item.detail),callback:function ($$v) {_vm.$set(item, "detail", $$v)},expression:"item.detail"}}):[_vm._v(" "+_vm._s(item.detail)+" ")]]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [(item.id > 1)?_c('v-select',{attrs:{"label":"","items":['$', '%']},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}}):[_vm._v(" "+_vm._s(item.unit)+" ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.type == 'Incentive')?[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.delIRow(item)}}},[_vm._v(" mdi-minus ")]),_c('v-icon',{attrs:{"small":""},on:{"click":_vm.addIRow}},[_vm._v(" mdi-plus ")])]:void 0]}}],null,true),model:{value:(_vm.payTypeTable.selected),callback:function ($$v) {_vm.$set(_vm.payTypeTable, "selected", $$v)},expression:"payTypeTable.selected"}})],1)]}},(_vm.errorMsg)?{key:"form.append.outer",fn:function(){return _vm._l((_vm.errorMsg),function(msg,index){return _c('div',{key:index,staticClass:"red--text",domProps:{"innerHTML":_vm._s(msg)}})})},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }