<template>
  <CRUDForm
    id="+"
    :fields="fields"
    ref="crudForm"
    @save="onFormSave"
    :formSize="900"
    headerText="Agent's Pay"
    :loading="loading"
    @close="onFormClose"
    :formActive="formActive"
  >
    <template v-slot:[`form.input.Agents`]="{}">
      <v-autocomplete
        v-model="selectedAgents"
        :items="agents"
        outlined
        chips
        color="blue-grey lighten-2"
        label="Agents*"
        item-text="name"
        item-value="id"
        multiple
        :rules="[(value) => !Array.isArray(value) || value.length > 0 || 'Required']"
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeAgent(data.item)"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.rate"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </template>
    <template v-slot:[`form.input.divider`]="{}">
      <template>
        <v-divider></v-divider>
      </template>
    </template>
    <template v-slot:[`form.input.PayType`]="{}">
      <v-card>
        <v-toolbar short flat>
          <v-toolbar-title>Pay Type</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider />

        <v-data-table
          v-model="payTypeTable.selected"
          item-key="id"
          hide-default-footer
          show-select
          :headers="payTypeTable.headers"
          :items="payTypeTable.items"
        >
          <template v-slot:[`item.amt`]="{ item }">
            <v-text-field label="" v-model="item.amt"></v-text-field>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <v-text-field
              label=""
              v-if="item.type == 'Incentive'"
              v-model="item.detail"
            ></v-text-field>
            <template v-else>
              {{ item.detail }}
            </template>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <v-select label="" v-if="item.id > 1" :items="['$', '%']" v-model="item.unit">
            </v-select>
            <template v-else>
              {{ item.unit }}
            </template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.type == 'Incentive'">
              <v-icon small class="mr-2" @click="delIRow(item)">
                mdi-minus
              </v-icon>
              <v-icon small @click="addIRow">
                mdi-plus
              </v-icon>
            </template>
            <template v-else> </template>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <template v-slot:[`form.append.outer`]="" v-if="errorMsg">
      <div v-html="msg" v-for="(msg, index) in errorMsg" :key="index" class="red--text" />
    </template>
  </CRUDForm>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";

export default {
  components: {
    CRUDForm
  },
  data() {
    return {
      formActive: false,
      errorMsg: [],
      curRowId: 3,
      selectedAgents: [],
      payTypeTable: {
        headers: [
          {
            text: "Type",
            sortable: false,
            value: "type"
          },
          {
            text: "Pay Detail",
            sortable: false,
            value: "detail"
          },
          {
            text: "Amount",
            sortable: false,
            value: "amt",
            width: "50px"
          },
          {
            text: "Unit",
            sortable: false,
            value: "unit",
            width: "100px"
          },
          {
            text: "",
            sortable: false,
            value: "actions",
            width: "80px"
          }
        ],
        items: [],
        initItems: [
          {
            id: 1,
            type: "Base Pay",
            detail: "Base Rate (leave blank to use global rate)",
            unit: "$ per hour",
            amt: null
          },
          {
            id: 2,
            type: "Base Pay",
            detail: "SA",
            unit: "%",
            amt: null
          },
          {
            id: 3,
            type: "Incentive",
            unit: "$",
            amt: null
          }
        ],
        selected: []
      },
      projectData: {},
      agents: [],
      loading: false,
      fields: [
        {
          text: "Agents",
          name: "Agents",
          input: {
            cols: "12"
          }
        },
        {
          text: "Start Date",
          name: "AStartDate",
          input: {
            type: "D",
            validation: ["mandatory"]
          }
        },
        {
          text: "End Date",
          name: "AEndDate",
          input: {
            type: "D",
            validation: ["mandatory"],
            value: null
          }
        },
        {
          text: "Pay Type",
          name: "PayType",
          input: {
            cols: "12"
          }
        }
      ]
    };
  },
  methods: {
    removeAgent(item) {
      const index = this.selectedAgents.indexOf(item.id);
      if (index >= 0) this.selectedAgents.splice(index, 1);
    },
    addIRow() {
      this.curRowId++;
      this.payTypeTable.items.push({
        id: this.curRowId,
        type: "Incentive",
        unit: "$",
        amt: null
      });
    },
    delIRow(item) {
      this.payTypeTable.items = this.payTypeTable.items.filter(function(obj) {
        return obj.id !== item.id;
      });
    },
    resetForm() {
      this.fields.find((e) => e.name == "AStartDate").input.value = new Date()
        .toJSON()
        .slice(0, 10);
      this.fields.find((e) => e.name == "AEndDate").input.value = new Date().toJSON().slice(0, 10);
      this.selectedAgents = undefined;
      this.payTypeTable.selected = [];
      this.payTypeTable.items = JSON.parse(JSON.stringify(this.payTypeTable.initItems));
    },
    open(id) {
      this.resetForm();
      this.loading = true;
      this.formActive = true;
      this.$axios
        .get("/ccms/getPAProjectInfo", {
          params: {
            projectId: id
          }
        })
        .then((res) => {
          let modifiedFields = ["AStartDate", "AEndDate"];
          this.$refs.crudForm.load(res.data.project, modifiedFields);
          this.agents = res.data.agents;
          this.projectData = res.data.project;
          this.fields.find((e) => e.name == "AEndDate").input.value = res.data.project.PEndDate;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFormSave(e) {
      this.$axios
        .post("/ccms/table/create", {
          projectData: this.projectData,
          tableName: "payAssignment",
          agents: this.selectedAgents,
          dates: e,
          data: this.payTypeTable.selected
        })
        .then((res) => {
          this.$store.commit("sendAlert", {
            msg: res.data.inserted + " record(s) added",
            color: "success"
          });
          if (res.data.errorMsg.length > 0) {
            this.errorMsg = res.data.errorMsg;
          }
        })
        .finally(() => {
          this.$refs.crudForm.saved();
          this.$emit("saved", true);
        });
    },
    onFormClose() {
      this.formActive = false;
      //this.$refs.crudForm.close();
    }
  }
};
</script>
